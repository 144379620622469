import React, { useState } from "react";
import KhataList from "./KhataList";
import Khata from "views/Khata";
import NoKhata from "views/NoKhata";
import { NewAccount, EditAccount } from "views/forms";
import useUniversalStore from "store/universalStore";
import Onboarding from "views/Onboarding";
import { ConfirmationModal } from "components";

const index = () => {
  const accountId = useUniversalStore((state) => state.accountId);

  
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 overflow-scroll p-0 m-0">
          <KhataList />
        </div>
        <div className="col-md-8 border-start border-secondary p-0 m-0">
          {accountId ? <Khata /> : <NoKhata />}
        </div>
      </div>

      <NewAccount />
      <EditAccount />
      <Onboarding />
    </div>
  )
}

export default index;