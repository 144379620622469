import { useState, useEffect } from "react";

import useUniversalStore from "store/universalStore";
import { profileAPI, accountAPI } from "services";
import { stringSort, numSort } from 'utils';
import { use } from "i18next";

const Filters = ({setFilteredAccounts}) => {
    const accounts = useUniversalStore((state) => state.accounts);
    const write = useUniversalStore((state) => state.write);
    const [hideClosed, setHideClosed] = useState(true);
    const [sort, setSort] = useState({ col: "name", desc: false });
    const [search, setSearch] = useState("");
    
    useEffect(() => {
        fetchAccounts();
    }, []);

    useEffect(() => {
        setFilteredAccounts(filterAccounts(accounts));
    }, [accounts, sort, hideClosed, search]);

    const fetchAccounts = async () => {
        const accounts = await accountAPI.getAccounts();
        
        //Write to store full accounts list
        write({ accounts: accounts });

        //Filter accounts based on search, sort and hideClosed
        const filteredAccounts = filterAccounts(accounts);
        setFilteredAccounts(filteredAccounts);
    }

    const handleSearchInput = (e) => setSearch(e.target.value);
    const clearSearch = () => setSearch("");

    const filterAccounts = (originalAccounts) => {
        if(!originalAccounts) return [];
        
        let _accounts = originalAccounts;
        if(search.trim()) {
          const searchTerm = search.toLowerCase();
          _accounts = _accounts.filter((account) =>
            account.get("name").toLowerCase().includes(searchTerm) ||
            account.get("phone")?.includes(search)
          );
        }
    
        _accounts = sort.col === "balance" 
          ? numSort(_accounts, sort.desc, sort.col)
          : stringSort(_accounts, sort.desc, sort.col);
      
        _accounts = hideClosed
          ? _accounts.filter(account => !account.get("cancelled")) 
          : _accounts;

        return _accounts;
    }
    
    const SearchBar = () => {
        return (
            <div className="input-group my-3 rounded-2">
                <span className="input-group-text btn border border-dark border-start-0 border-top-0 border-bottom-0" id="inputgroupicon">
                    <i className="bi bi-search"></i>
                </span>
                <input
                    type="text"
                    autoFocus
                    className="form-control py-0 col-8 accountsearch btn text-start"
                    placeholder="Search accounts"
                    value={search}
                    onChange={handleSearchInput}
                />
                {search && (
                    <button  className="btn btn-outline-secondary" 
                        onClick={clearSearch}
                        type="button">
                        <i className="bi bi-x"></i>
                    </button>
                )}
            </div>
        )
    }
    
    return (
        <div className="justify-content-around d-flex align-items-baseline border shadow">
            <SearchBar />
          
            <span className="w-25">
                <i className={`fs-4 px-4 bi  
                    ${hideClosed ? "bi-filter-circle-fill text-app": "bi-filter"}`}
                    role="button"
                    onClick={(e) => setHideClosed(!hideClosed)}></i>
                <i className="bi bi-arrow-down-up dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"></i>
                <ul className="dropdown-menu">
                    <li className="dropdown-item d-flex"
                        onClick={(e) => setSort({ col: "name", desc: !sort.desc })}>
                        <span className="me-auto">Name</span>
                        <span className={sort.col === "name" ? "" : "invisible"}>
                            <i className={`bi ${sort.desc ? 
                                "bi-sort-alpha-down" : "bi bi-sort-alpha-up"}`}></i>
                        </span>
                    </li>
                    <li className="dropdown-item d-flex"
                        onClick={(e) => setSort({ col: "balance", desc: !sort.desc } )}>
                        <span className="me-auto">Balance</span>
                        <span className={sort.col === "balance" ? "" : "invisible"}>
                            <i className={`bi ${sort.desc ?
                                "bi-sort-numeric-up-alt" : "bi bi-sort-numeric-down"}`}></i>
                        </span>
                    </li>
                </ul>
            </span>
        </div>
    )
}

export default Filters;