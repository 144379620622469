
function formatMoneySimply(amount, locale = 'en-IN') {
    return "Rs. " + new Intl.NumberFormat(locale).format(amount);
}

function formatMoney(amount,decimalCount = 0,decimal = '.',thousands = ',') {
    try {
        decimalCount = Math.abs(decimalCount)
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount

        const negativeSign = amount < 0 ? '-' : ''

        let i = parseInt(
            (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString()
        let j = i.length > 3 ? i.length % 3 : 0

        return (
            negativeSign +
            '₹ ' +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (decimalCount
                ? decimal +
                  Math.abs(amount - i)
                      .toFixed(decimalCount)
                      .slice(2)
                : '')
        )
    } catch (e) {
        console.log(e)
    }
}

function debounce(f, ms) {
    let debounceTimeoutId

    return function (...args) {
        if (debounceTimeoutId) {
            clearTimeout(debounceTimeoutId)
        }

        debounceTimeoutId = setTimeout(() => {
            f.apply(this, args)
        }, ms)
    }
}

function stringSort(array, isDescending, column) {
    if(!array) return [];
    //console.log('array', array);
    return array.slice().sort((a, b) =>
        isDescending 
            ? b?.get(column).localeCompare(a.get(column))
            : a?.get(column).localeCompare(b.get(column)));
}

function numSort(array, isDescending, column) {
    if(!array) return [];

    return array.slice().sort((a,b) => 
        isDescending 
            ? a.get(column) - b.get(column)
            : b.get(column) - a.get(column));    
}

function toggleOffCanvas(offcanvasName){
    const offCanvas = document.getElementById(offcanvasName);
    let offCanvasInstance = bootstrap.Offcanvas.getInstance(offCanvas);
    if (!offCanvasInstance) {
        offCanvasInstance = new bootstrap.Offcanvas(offCanvas);
    }
    offCanvasInstance?.toggle();
}

const base64ToBlob = (base64, mime) => {
    mime = mime || '';
    const sliceSize = 1024;
    const byteChars = atob(base64);
    const byteArrays = [];
  
    for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
      const slice = byteChars.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: mime });
  };

export {
    formatMoney,
    debounce,
    stringSort,
    numSort,
    toggleOffCanvas,
    base64ToBlob
}