import Parse from './parse.service'

export const fetchUser = () => {
    return Parse.User.current()
}

export function logout() {
    return Parse.User.logOut()
}

export const updateEmail = async (email) => {
    try {
        const user = fetchUser();
        user.set('email', email);
        await user.save();
        return user;
    } catch (err) {
        return false
    }
}

export const sendVerificationEmail = async (email) => {
    try {
        const user = fetchUser();
        user.set('email', email);
        var result = await user.save();
        return result;
    } catch (err) {
        return false
    }
}

export const loginUsingSession = async (sessionToken) => {
    try {
        const res = await Parse.User.become(sessionToken)
        return { status: "success", response: res };
    } catch (error) {
        return { status: "error", response: error };
    }
  }
  