import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, Navigate, useLocation } from 'react-router-dom'
import { auth, profileAPI } from 'services';
import { metrics } from 'assets/themes'
import { useForm } from 'react-hook-form'
//import { useOnboardingStore } from 'store/onboarding-store'


function Onboarding() {
    const { t } = useTranslation()
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm()

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null)
    const [showModal, setShowModal] = useState(false)
    // const writeOnboardingStore = useOnboardingStore(
    //     (state) => state.writeOnboardingStore
    // )
    // const location = useLocation()
    // const router = useNavigate()

    useEffect(() => {
        checkOnboarding();
    }, [])

    const checkOnboarding = async () => {
        const profile = await profileAPI.getProfile();
        if (!profile || !profile?.get('businessName') && !profile?.get('typeOfBusiness')) {
            setShowModal(true)
        }
    }

    const handleOnboarding = async ({ businessName, typeOfBusiness }) => {
        if (!businessName || !typeOfBusiness) {
            return
        }

        setLoading(true)
        const status = await profileAPI.updateProfile({typeOfBusiness, businessName});
        setLoading(false)
        setSuccess(true)
        await new Promise((resolve) => setTimeout(resolve, 2000)) // 2 sec - wait for success message
        setShowModal(false)
        // writeOnboardingStore({
        //     showOnboardingTasks: true
        // })
    }

    const onClick = () => {
        console.log("clicked");
    }

    // if (!user) {
    //     return <Navigate to="/login" />
    // }


    // <div className={`modal ${showModal ? 'show' : 'hide'}`} 
    //             tabIndex="-1" 
    //             style={{ display: showModal ? 'block' : 'none' }} 
    //             aria-hidden={!showModal}>
    //         <div className="modal-header">
    //             <h5 className="modal-title">Onboarding</h5>
    //         </div>
    //         <div className="mx-auto col-md-4 col-xs-6 p-4 bg-light rounded-3">
    //             <div className="d-flex">
    //                 <img src={metrics.logo} className="mx-auto w-25"></img>
    //             </div>
    //             <span className="d-flex w-full gap-2 justify-content-center">
    //                 <h1 className="my-auto text-center mb-3">
    //                     {t('Welcome to')}
    //                 </h1>
    //                 <h1 className="my-auto text-center text-danger mb-3">
    //                     {t('Bahi Khata')}
    //                 </h1>
    //             </span>
    //             <form onSubmit={handleSubmit((data) =>
    //                     handleOnboarding(data).catch((err) => {
    //                         console.log('err', err)
    //                         setSuccess(false)
    //                     })
    //                 )}>
    //                 <div className="w-100 mb-4">
    //                     <input
    //                         className="form-control"
    //                         placeholder="Business Name"
    //                         {...register('businessName', {
    //                             required: true,
    //                             validate: (value) => value.trim() !== ''
    //                         })}
    //                     />
    //                     {errors.businessName && (
    //                         <span className="mt-1 text-danger">
    //                             {t('Business Name is required')}
    //                         </span>
    //                     )}
    //                 </div>
    //                 <div className="w-100 mb-4">
    //                     <select id="typeOfBusiness"
    //                         className="form-select"
    //                         defaultValue="placeholder"
    //                         {...register('typeOfBusiness', {
    //                             required: true,
    //                             validate: (value) => value !== 'placeholder'
    //                         })}>
    //                         <option disabled value={'placeholder'}>
    //                             {t('Type of Business')}
    //                         </option>
    //                         <option value="service">{t('Service')}</option>
    //                         <option value="manufacturing">
    //                             {t('Manufacturing')}
    //                         </option>
    //                         <option value="trading"> {t('Trading')}</option>
    //                     </select>
    //                     {errors.typeOfBusiness && (
    //                         <span className="mt-1 text-danger">
    //                             {t('Type of Business is required')}
    //                         </span>
    //                     )}
    //                 </div>
    //                 {success && (
    //                     <div className="alert alert-success">
    //                         {t('Onboarding successful!')}
    //                     </div>
    //                 )}
    //                 {success === false && (
    //                     <div className="alert alert-danger">
    //                         {t('Something went wrong!')}
    //                     </div>
    //                 )}
    //                 <div className="d-grid">
    //                     <button
    //                         type="submit"
    //                         className={
    //                             loading
    //                                 ? 'btn btn-danger shadow disabled'
    //                                 : 'btn btn-danger shadow'
    //                         }
    //                         id="onboardingSubmitBtn">
    //                         <span className={
    //                                 loading
    //                                     ? 'spinner-border spinner-border-sm'
    //                                     : ''
    //                             }
    //                         ></span>
    //                         {loading ? ' Loading...' : 'Submit'}
    //                     </button>
    //                 </div>
    //             </form>
    //         </div>
    //     </div>

    return (
        <div className={`modal ${showModal ? 'show' : 'hide'}`} id="onboardingModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h1 className="modal-title fs-5" id="">
                Onboarding
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-footer border-0">
                <div className="d-flex">
                    <img src={metrics.logo} className="mx-auto w-25"></img>
                </div>
                <span className="d-flex w-full gap-2 justify-content-center">
                    <h1 className="my-auto text-center mb-3">
                        {t('Welcome to')}
                    </h1>
                    <h1 className="my-auto text-center text-danger mb-3">
                        {t('Bahi Khata')}
                    </h1>
                </span>
              <button type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={onClick}>
                <i className="bi bi-trash-fill"></i> Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Onboarding
