import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { profileAPI } from "services";
import { Loader, Status } from "components";
import useUniversalStore from "store/universalStore";
import EmailVerify from "./EmailVerify";

function Profile() {
  const { profile, write } = useUniversalStore((state) => ({
    profile: state.profile,
    write: state.write
  }));
  const navigate = useNavigate();
  
  const initialValues = {
    phone: "",
    businessName: "",
    typeOfBusiness: "",
    email: "",
    loading: false,
    error: {type: null, message: ""}
  }

  const [values, setValues] = useState(initialValues);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setValues({
      phone: profile?.get("phone") || "",
      businessName: profile?.get("businessName") || "",
      typeOfBusiness: profile?.get("typeOfBusiness") || "",
      email: profile?.get("email") || "",
      loading: false, 
      error: {type: null, message: ""}
    });
  }, []);

  const saveProfile = async (e) => {
    e.preventDefault();

    //Update Profile with new values
    const result = await profileAPI.updateProfile(values);
    
    //Update Global State
    write({profile: result});
    
    //Reset Edit Mode
    setIsEditing(false);
  }
  
  // const success = (message) => {
  //   setValues({
  //     ...values,
  //     successmsg: message,
  //     error: {type: null, message: ""}
  //   })

  //   setTimeout(() => setValues({...values,error: ""}), 5000);
  // }

  // const failure = (message) => {
  //   setValues({
  //     name: user.get("name"),
  //     phone: user.get("phone"),
  //     username: user.get("username"),
  //     email: user.get("email"),
  //     businessName: user.get("businessName"),
  //     typeOfBusiness: user.get("typeOfBusiness"),
  //     error: {type: "error", message: message}
  //   });

  //   setTimeout(() => setValues({...values,error: {type: null, message: ""}}), 5000);
  // }

  const updateField = (field, value) => {
    setValues({...values, [field]: value });
  }

  if(values.loading) return <Loader />;

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-1">
          <a role="button" className="btn btn-app" 
            onClick={() => navigate(-1)} 
            title="Back">
            <i className="bi bi-arrow-left"></i>
          </a>
        </div>
        <div className="col-11">
          <h1 className="">Profile Details</h1>
        </div>
      </div>
      <hr />
      <div className="row">
        <table className="my-4 table">
          <tbody className="mb-4">
            <tr>
              <th scope="row" className="fw-bold fs-5 py-3">Phone :</th>
              <td>
                  <input
                      className="form-control"
                      type="tel"
                      value={values.phone || ""}
                      onChange={(e) => updateField("phone", e.target.value)}
                      disabled
                  />
              </td>
            </tr>
            <tr>
              <th scope="row" className="fw-bold fs-5 py-3">Business Name :</th>
              <td>
                  <input
                      className="form-control"
                      type="text"
                      onChange={(e) => updateField("businessName", e.target.value)}
                      value={values.businessName || ""}
                      disabled={!isEditing}
                  />
              </td>
            </tr>
            <tr>
              <th scope="row" className="fw-bold fs-5 py-3">Type of Business :</th>
              <td>
                  <select id="typeOfBusiness"
                      className="form-select"
                      defaultValue={values.typeOfBusiness || ""}
                      disabled={!isEditing}
                      onChange={(e) => updateField("typeOfBusiness", e.target.value)}>
                      <option value="service">Services</option>
                      <option value="manufacturing">Manufacturing</option>
                      <option value="trading">Trading</option>
                  </select>
              </td>
            </tr>
            <tr>
              <th scope="row" className="fw-bold fs-5 py-3">Email :</th>
              <td colSpan="2" className="position-relative">
                <input className="form-control"
                  type="email"
                  value={values.email || ""}
                  onChange={(e) => updateField("email", e.target.value)}
                  disabled={!isEditing} />
                  <EmailVerify />
              </td>
            </tr>
          </tbody>
        </table>
        <Status type={values.error.type} message={values.error.message} />
        <button className="btn btn-app py-3 my-5"
          onClick={isEditing ? saveProfile : () => setIsEditing(true)}>
          <b className="fs-5">{isEditing ? 'Save Profile' : 'Edit Profile'}</b>
        </button>
      </div>
      
    </div>
  )
}
export default Profile;
