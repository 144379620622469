import { useState } from "react";
import { api } from "services";
import useUniversalStore from "store/universalStore";

const ReportsView = () => {
    const accountId = useUniversalStore((state) => state.accountId);
    const [reportLoading, setReportLoading] = useState(false);

    const handleDownload = async (value) => {
        const endMonth = new Date();
        let startMonth = new Date();
        startMonth.setHours(0, 0, 0, 0);
        if (value === 1) {
            startMonth.setDate(1);
            setReportLoading(true);
        } else if (value === "lifetime") {
            setReportLoading(true);
            startMonth = "lifetime";
        } else {
            startMonth.setMonth(endMonth.getMonth() - value);
            setReportLoading(true);
        }
        // console.log(startMonth, endMonth);
        const result = await api.downloadMonthlyReport(accountId, startMonth, endMonth);
        setReportLoading(false);
        if (result == null) {
            alert(
                "Something went wrong! Could not download report. Please try again later."
            );
        }
    }

    return (
      <div className="dropdown">
        <button className="btn btn-sm bg-app dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          title="Reports">
          <div className="d-flex align-items-center">
            {reportLoading ? (
              <span className="spinner-border text-white" role="status" aria-hidden="true"></span>
            ) : (
              <i className="bi bi-file-earmark-arrow-down" style={{ fontSize: "2rem", color: "white" }}></i>
            )}
          </div>
        </button>

        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li>
            <button className="dropdown-item" onClick={() => handleDownload(1)}>
              Latest Month
            </button>
          </li>
          <li>
            <button className="dropdown-item" onClick={() => handleDownload(3)}>
              3 Months
            </button>
          </li>
          <li>
            <button className="dropdown-item" onClick={() => handleDownload(6)}>
              6 Months
            </button>
          </li>
          <li>
            <button className="dropdown-item" onClick={() => handleDownload(12)}>
              1 Year
            </button>
          </li>
          <li>
            <button className="dropdown-item" onClick={() => handleDownload("lifetime")}>
              Lifetime
            </button>
          </li>
        </ul>
      </div>
    );
};

export default ReportsView;