import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.css";

const ExpandableButton = (props) => {
    const { title, clickEvent, icon, clickForm } = props;
    return (
        <div className="justify-content-end d-flex position-relative">
            <button
              className="second-step btn btn-app expand-btn"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target={`#${clickForm}`}
              onClick={clickEvent}>
              <FontAwesomeIcon
                icon={icon}
                className="fs-2 ms-2"/>
              <span className="fs-3 ms-3 text-nowrap fw-bold button-text">
                {title}
              </span>
            </button>
        </div>
    )
}

export default ExpandableButton;