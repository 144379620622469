const Colors = {
  primary: getComputedStyle(document.documentElement).getPropertyValue("--primary"),
  primaryLight: getComputedStyle(document.documentElement).getPropertyValue("--lightPrimary"),
  primaryDark: getComputedStyle(document.documentElement).getPropertyValue("--darkPrimary"),
  secondary: getComputedStyle(document.documentElement).getPropertyValue("--secondary"),
  accent: getComputedStyle(document.documentElement).getPropertyValue("--accent"),
  background: getComputedStyle(document.documentElement).getPropertyValue("--background"),
  black: getComputedStyle(document.documentElement).getPropertyValue("--black"),
  warning: getComputedStyle(document.documentElement).getPropertyValue("--warning"),
  blackOpacity: getComputedStyle(document.documentElement).getPropertyValue("--blackOpacity"),
  blackLight: getComputedStyle(document.documentElement).getPropertyValue("--blackLight"),
  white: getComputedStyle(document.documentElement).getPropertyValue("--white"),
  whiteOpacity: getComputedStyle(document.documentElement).getPropertyValue("--whiteOpacity"),
  grey: getComputedStyle(document.documentElement).getPropertyValue("--grey")
};

export default Colors;