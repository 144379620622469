import Parse from "./parse.service";
import { base64ToBlob } from "../utils";

const Account_managers = Parse.Object.extend("Account_managers");
const Contacts = Parse.Object.extend("Contacts");

import { auth } from "./index";
import moment from "moment";

export function fetchUser() {
  return Parse.User.current();
}

// Account Managers

export const accountManagers = async () => {
  const query = new Parse.Query(Account_managers);
  const results = await query.find();
  return results;
};

// Monthly Reports
export const downloadMonthlyReport = async (id, startMonth, endMonth) => {
  const params = {
    id: id,
    startMonth: startMonth,
    endMonth: endMonth,
  };
  const reportBase64String = await Parse.Cloud.run("getMonthlyreport", params);
  
  if (startMonth !== "lifetime") {
    startMonth.setDate(startMonth.getDate() + 1);
  }

  const blob = base64ToBlob(reportBase64String, { type: "application/pdf" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `Bahi Khata - (${
    startMonth === "lifetime"
      ? ""
      : moment(startMonth).format("MMM DD YYYY") + " - "
  }${moment(endMonth).format("MMM DD YYYY")}).pdf`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
  return "success";
};


export const getContacts = async () => {
  try {
    const query = new Parse.Query(Contacts);
    query.equalTo("userId", auth.fetchUser());
    return await query.find();
  } catch (error) {
    return error;
  }
};
