import React, {
  useState,
  useEffect,
  useRef
} from "react";
import DatePicker from "react-date-picker";
import { sms, transactionAPI, accountAPI } from "services";
import txnaudio from "assets/sounds/google_pay-mini.mp3";
import FilePicker from "views/FilePicker";
import Creatable from "react-select/creatable";
import useUniversalStore from "store/universalStore";
import { Loader, Status } from "components";
import { formatMoney } from "utils";
import { Colors } from "assets/themes";

const OneTimeTxn = () => {
  const { accounts, accountId, profile, write } = useUniversalStore((state) => ({
    accounts: state.accounts,
    accountId: state.accountId,
    profile: state.profile,
    write: state.write
  }));

  const initialState = {
    amount: "",
    date: new Date(),
    account_name: "",
    details: "",
    attachment: null,
    loading: false,
    status: { type: null, message: "" }
  };

  const [isKHATA, setIsKHATA] = useState(false);
  const [values, setValues] = useState(initialState);
  const [multipler, setMultipler] = useState(1);
  const [notify, setNotify] = useState(true);
  const [options, setOptions] = useState([]);
  const childRef = useRef();
  const MAX_TRANSFER_AMOUNT = 10000000;
  
  useEffect(() => {
    setupEventListeners();
  }, []);

  useEffect(() => {
    setupEventListeners();
    populateAccountName();
  }, [accountId]);

  // Populate account Name dropdown with active accounts
  useEffect(() => {
    populateAccountsDropdown(accounts?.filter((account) => !account.get("cancelled")));
  }, [accounts]);

  // Clear notification when account name is removed
  useEffect(() => {
    if (values.account_name === "") {
      setNotify(false);
    } else {
      setNotify(true);
    }
  }, [values.account_name]);

  const setupEventListeners = () => {
    const offCanvas = document.getElementById("addTxnDrawer");
    if(!offCanvas) return;

    const handleHidden = () => {
      setValues(initialState);
      childRef.current.removePhoto();
      
      // Reset Khata name preselected and disabled
      setIsKHATA(false);
    };

    const handleShown = () => {
      //populateAccountsDropdown(accounts?.filter((account) => !account.get("cancelled")));
      populateAccountName();
    };

    offCanvas.addEventListener("hidden.bs.offcanvas", handleHidden);
    offCanvas.addEventListener("shown.bs.offcanvas", handleShown);

    return () => {
      //remove event listeners when offcanvas is hidden
      offCanvas.removeEventListener("hidden.bs.offcanvas", handleHidden);
      offCanvas.removeEventListener("shown.bs.offcanvas", handleShown);
    };
  }

  const populateAccountName = async () => {
    if(accountId) {
      const account = await accountAPI.getAccountById(accountId);
      setValues({
          ...values,
          account_name: account.get("name")
        });
        setIsKHATA(true);
    }
  }

  const populateAccountsDropdown = (_accounts) => {
    const _options = [];
    for (var i = 0; i < _accounts.length; i++) {
      _options.push({
        value: _accounts[i].get("name"),
        label: _accounts[i].get("name"),
      });
    }
    setOptions(_options);
  };

  const failure = (msg) => {
    setValues({
      ...values,
      errormsg: msg,
    });
    setTimeout(() => {
      setValues({
        ...values,
        errormsg: "",
      });
    }, 4000);
  };

  const amountChangeHandler = (e) => {
    e.preventDefault();
    //goToNextStepDebounced();
    let amountValue = e.target.value.replace(/[₹,]/g, "");
    setValues((prev) => {
      let tempValue = { ...prev };
      if (amountValue === NaN || amountValue === "") {
        tempValue.amount = 0;
        return tempValue;
      } else if (amountValue <= MAX_TRANSFER_AMOUNT) {
        tempValue.amount = parseFloat(amountValue.replace(/[^0-9.-]/g, ""));
        return tempValue;
      } else {
        failure("Enter Amount less than "+ MAX_TRANSFER_AMOUNT);
        return tempValue;
      }
    });
  };

  const detailsChangeHandler = (e) => {
    e.preventDefault();
    //goToNextStepDebounced();
    setValues({
      ...values,
      details: e.target.value,
    });
  };

  const accountChangeHandler = (e) => {
    // if (e?.value && currentTaskId === "new-cashbook" && currentStep === 2) {
    //   goToNextStepDebounced();
    // }
    setValues({
      ...values,
      account_name: e ? e.value : "",
    });
  };

  const accountContainsPhoneNumber = () => {
    const accountName = values.account_name;
    const temp = accounts.filter((account) => account.get("name") === accountName);
    const tempAccount = temp[0];
    if (tempAccount.get("phone")) {
      return true;
    }
    return false;
  };

  const onDateChange = (date) => {
    setValues({
      ...values,
      date: date,
    });
  }

  const notifyChangeHandler = (e) => {
    //goToNextStepDebounced();
    if (values.account_name) {
      if (accountContainsPhoneNumber()) {
        setNotify((value) => !value);
      } else {
        failure("Account has no phone number");
      }
    } else {
      setNotify(() => false);
      failure("Select an account to notify");
    }
  };

  // const fetchBillUntilFound = (txnId) => {
  //     const myInterval = setInterval(() => {
  //         api.getTransactionById(txnId).then((txn) => {
  //             if (txn.get("attachment") !== "https://bahikhatabills.s3.amazonaws.com/cb97925b28803a604429df0b4fb43f6f_download1.png") {
  //                 // console.log("yeessssssssss");
  //                 // updateStates(false, false, txn);
  //                 clearInterval(myInterval);
  //             }
  //         })
  //     }, 1000);
  // }

  const submitAddTransaction = async (e) => {
    e.preventDefault();

    if (values.amount > 0 && values.amount <= MAX_TRANSFER_AMOUNT) {
      //const dialog = document.getElementById("Loadingdialog");
      //dialog.showModal();
      
      //Add current time to the date
      const timestamp = new Date();
      // timestamp.setHours(timestamp.getHours(), timestamp.getMinutes(), timestamp.getSeconds());
      
      try {
        const result = await transactionAPI.transaction(
          values.account_name,
          values.amount * multipler,
          values.details,
          values.attachment,
          values.date
        );

        //Update transactions in global store
        if(accountId) {
          write({
            transactions: await transactionAPI.getTransactionsByAccountIdWithMonth(accountId, values.date),
            accounts: await accountAPI.getAccounts()
          });
        } else {
          write({
            transactions: await transactionAPI.getTransactionsByDate(values.date)
          });
        }

        //Send SMS if the account has a phone number and notify is checked
        if (result.get("accountId")?.get("phone") !== "" && notify) {
            const amount = result.get("amount");
            const phone = "91" + result.get("accountId").get("phone");
            const partyName = profile?.get("businessName") ? profile.get("businessName") : profile.get("phone");

            if (result.get("amount") < 0) {
              // Send Message to Party
              sms.sendPaidSMS(Math.abs(amount),partyName,accountId, phone);
            } else {
              // Send Message to Party
              sms.sendReceiveSMS(Math.abs(amount),partyName,accountId, phone);
            }
        }

        successaudio();
        successpopup();
        // setNotify(true);
      } catch (error) {
        console.log("Error while adding transaction: ", error);
      }
    } else {
      failure("Enter an amount between 0 and 99,99,999₹.");
    }
  };

  const fileFetched = (file) => {
    console.log(file);
    setValues({
      ...values,
      attachment: file,
    });
  };

  const successaudio = () => {
    var audio = new Audio(txnaudio);
    audio.play();
  };

  const successpopup = () => {
    // const successdialog = document.getElementById("successdialog");
    // // Show Success popup
    // successdialog.showModal();
    // // Hide Success Popup
    // setTimeout(() => {
    //   successdialog.close();
    // }, 3000);
  };

  if(values.loading) return <Loader />;
  return (
    <div className="row">
      <div className="col-12">
        <form className="needs-validation" 
          onSubmit={submitAddTransaction} 
          id="newtxnform">
          <div className="row py-3">
            <div className="col-md-6">
              <label
                className="fw-bolder fs-smaller fonts-for-buttons-label"
                htmlFor="txnamount">
                Amount
              </label>
            </div>
            <div className="col-md-6">
              <label
                className="fw-bolder fs-smaller fonts-for-buttons-label">
                Date
              </label>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className="seventh-step form-control py-2"
                id="txnamount"
                placeholder="Amount"
                value={formatMoney(values.amount)}
                onChange={amountChangeHandler}
                required />
            </div>
            <div className="col-md-6">
              <DatePicker
                className="datePicker"
                id="date"
                value={values.date}
                onChange={onDateChange}
                calendarIcon={<i className="bi bi-calendar-date text-white"></i>}
                maxDate={new Date()}
                clearIcon={null}
                format="dd-MM-yy" />
            </div>
          </div>
          <div className="select-user-step py-3">
            <Creatable
              options={options}
              value={ values.account_name ? {
                      value: values.account_name, label: values.account_name 
                    } : null
              }
              classNamePrefix="rs"
              isClearable
              isDisabled={isKHATA}
              placeholder="Select Party Name"
              onChange={accountChangeHandler}
              styles={{
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isSelected ? Colors.white : Colors.black,
                  fontSize: 18,
                  backgroundColor:
                    state.isFocused || state.isSelected
                      ? Colors.primary
                      : Colors.white,
                }),
                menuList: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: Colors.white,
                }),
                clearIndicator: () => ({
                  color: Colors.primary,
                }),
              }}
            />
          </div>
          <div className="py-3">
            <input
              type="text"
              className="eighth-step form-control"
              placeholder="Details"
              maxLength="50"
              value={values.details}
              onChange={detailsChangeHandler} />
          </div>
          <div className="my-3">
            <FilePicker onPick={fileFetched} ref={childRef} />
          </div>
          <div className="py-3 send-sms-step ">
            <div className="form-check form-switch">
              <input
                className="form-check-input primary p-2" 
                size={"lg"}
                type="checkbox"
                onChange={notifyChangeHandler}
                id="sendSMSCheckbox"
                checked={notify ? true : false} />
              <label
                className="form-check-label px-2"
                htmlFor="sendSMSCheckbox">
                Send SMS Notification
              </label>
            </div>
          </div>
          {values.errormsg ? (
            <div className="form-group text-start animate__animated animate__pulse">
              <div
                className="form-check-label alert alert-danger text-capitalized"
                id="newacerror">
                <i className="bi bi-exclamation-circle-fill"></i>{" "}
                {values.errormsg}
              </div>
            </div>
          ) : (
            <div className="py-2"></div>
          )}
          <div className="row my-3 sticky-bottom-drawer">
            <div className="col-md-6 d-grid mt-md-0">
              <button
                type="submit"
                className="btn btn-success block"
                data-bs-dismiss={ values.amount > 0 && values.amount < MAX_TRANSFER_AMOUNT
                    ? "offcanvas": null}
                onClick={() => {
                  //goToNextStep();
                  setMultipler(1);
                }}
              >
                <b className="fs-5 text-uppercase"><span className="fs-4">+</span> You Received</b>
              </button>
            </div>
            <div className="col-md-6 d-grid mt-md-0">
              <button type="submit"
                className="btn btn-danger block py-3"
                data-bs-dismiss={ values.amount > 0 && values.amount < MAX_TRANSFER_AMOUNT
                    ? "offcanvas" : null}
                onClick={() => {
                  //goToNextStep();
                  setMultipler(-1);
                }}>
                <b className="fs-5 text-uppercase"><span className="fs-4">-</span> You Paid</b>
              </button>
            </div>
          </div>
          <br />
          <Status type={values.status.type} message={values.status.message} />
        </form>
      </div>
    </div>
  );
};

export default OneTimeTxn;
