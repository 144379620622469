import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { accountAPI, api } from "services";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { debounce, toggleOffCanvas } from "utils";
import { Loader, Status, ProfilePhoto } from "components";
import useUniversalStore from "store/universalStore";
import Creatable from "react-select/creatable";
import { RiCloseCircleFill } from "react-icons/ri";

const NewAccount = () => {
  // TODO : Accounts list refresh after adding a new account.
  // TODO : New account not getting added.
  // TODO : Trying to Add new account .... adds same as phone number too... 
  // TODO : Profile Photo not being added... 
  // TODO : New account created doesn't show up Close button.
  // TODO : Allow adding new account without profile photo.
  
  const [invalidPhone, setInvalidPhone] = useState(true);
  const [allContacts, setAllContacts] = useState([]);
  const [options, setOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const initialState = {
    name: "",
    phone: { value: "", error: "" },
    address: "",
    detail: "",
    profilePhoto: { url: null, loading: false },
    loading: false,
    status: { type: null, message: "" },
  };
  const [values, setValues] = useState(initialState);
  const write = useUniversalStore((state) => state.write);
  
  const fetchContacts = async () => {
    setIsFetching(true);
    try {
      const res = await api.getContacts();
      if (res && res[0]) {
        const contacts = res[0].get("phonebook") || [];
        setAllContacts(contacts);
      }
    } catch (error) {
      console.error("Failed to fetch contacts", error);
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchContacts();
  }, []);

  const contactOptions = useMemo(() => {
    return allContacts.map((contact) => ({
      value: contact.number.replace(/\s+/g, ""),
      label: contact.name,
    }));
  }, [allContacts]);

  useEffect(() => {
    setOptions(contactOptions);
  }, [contactOptions]);


  useEffect(() => {
    const offCanvas = document.getElementById("addAccountDrawer");
    if (!offCanvas) return;

    const handleHidden = () => {
      setValues(initialState);
    };

    offCanvas.addEventListener("hidden.bs.offcanvas", handleHidden);

    return () => {
      offCanvas.removeEventListener("hidden.bs.offcanvas", handleHidden);
    };
  }, []);

  /*useEffect(() => {
    setInvalidPhone(
      !(validatePhoneNumber(values.phone.value) ||
        values.phone.value == "")
    );
    
  }, [values.phone.value]);*/

  function validatePhoneNumber(phoneNumber) {
    console.log('phoneNumber', phoneNumber);
    if(removeCountryCode(phoneNumber).length  != 10) return false;
    return true;
  }
  
  function removeCountryCode(phoneNumber) {
    const code = phoneNumber.replace(/\s+/g, "").replace("+91", "");
    //console.log(code);
    return code;
  }

  const addAccount = async (e) => {
    e.preventDefault();
    setValues((prev) => ({ ...prev, loading: true }));

    try {
      const account = await accountAPI.getAccountByName(values.name);
      if (account) {
        setValues((prev) => ({
          ...prev,
          loading: false,
          status: {
            type: "error",
            message: "Account with this name already exists.",
          },
        }));
      } else {
        const result = await accountAPI.createAccount(
          values.name,
          removeCountryCode(values.phone),
          values.address,
          values.detail,
          values.profilePhoto.url
        );

        //Refresh data in global state
        write({accounts: accountAPI.getAccounts()});

        setValues({
          ...values,
          status: { type: "success", message: "Account created successfully!" },
        });

        setTimeout(() => {
          toggleOffCanvas("addAccountDrawer");
        }, 500);
      }
    } catch (error) {
      setValues((prev) => ({
        ...prev,
        loading: false,
        status: { type: "error", message: error.message || "Failed to create account." },
      }));
    }
  };

  const updateField = useCallback((field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleProfilePhotoChange = useCallback((e) => {
    if (e.target.files && e.target.files[0]) {
      updateField("profilePhoto", {url: e.target.files[0], loading: true});
    }
  }, [updateField]);

  const accountChangeHandler = (selectedOption) => {
    if (!selectedOption || !selectedOption.value || !selectedOption.label) {
      setValues((prev) => ({
        ...prev,
        name: "",
        phone: "",
      }));
      return;
    }
    setValues((prev) => ({
      ...prev,
      name: selectedOption.label,
      phone: selectedOption.value,
    }));
  }

  const selectContacts = useCallback(() => (
    <Creatable
      options={options}
      value={values.name
          ? { label: values.name, value: values.phone }
          : null
      }
      classNamePrefix="rs"
      isClearable
      placeholder="Select Party Name from Contact"
      onChange={accountChangeHandler}
      styles={{
        option: (baseStyles, state) => ({
          ...baseStyles,
          color: state.isSelected ? "white" : "black",
          fontSize: 18,
          backgroundColor: state.isFocused
            ? "#FFCCCB"
            : state.isSelected
            ? "#dc3545"
            : "white",
        }),
        menuList: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: "white",
        }),
        clearIndicator: (baseStyles) => ({
          ...baseStyles,
          color: "red",
        }),
      }}
    />
  ), [options, values.name, values.phone, accountChangeHandler]);

  if(values.loading) return <Loader />;

  return (
    <div className="offcanvas offcanvas-start text-danger"
      tabIndex="-1"
      id="addAccountDrawer">
      <div className="offcanvas-header bg-app text-light">
        <h3 className="offcanvas-title">Add Account</h3>
        <button
          type="button"
          className="btn-close text-reset bg-light"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body container-fluid">
        <div className="row">
          <div className="col-12">
            <form onSubmit={addAccount} id="newaccountform" className="mb-0">
              <div className="my-5" align="center">
                <ProfilePhoto 
                  url={values.profilePhoto?.url}
                  loading={values.profilePhoto?.loading} 
                  onChange={handleProfilePhotoChange} />
              </div>
              <div className="mb-3">
                {isFetching ? "Loading contacts..." : selectContacts()}
              </div>
              <div className="mb-3">
                <input
                  type="number"
                  className={`form-control ${
                    invalidPhone ? "is-invalid" : values.phone ? "is-valid" : ""
                  }`}
                  placeholder="Phone no."
                  value={values.phone.value}
                  onChange={(e) => updateField("phone", e.target.value)}
                />
                {invalidPhone && (
                  <div className="invalid-feedback">
                    Please enter a valid phone number.
                  </div>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className={`form-control ${values.address ? "is-valid" : ""}`}
                  placeholder="Address"
                  value={values.address}
                  onChange={(e) => updateField("address", e.target.value)} />
              </div>
              <div className="mb-3">
                <textarea
                  className={`form-control ${values.markup ? "is-valid" : ""}`}
                  placeholder="Other Details"
                  value={values.detail}
                  onChange={(e) => updateField("detail", e.target.value)}
                  rows="3" />
              </div>
              <Status type={values.status.type} message={values.status.message} />
              <div className="row sticky-bottom-drawer pb-3">
                <div className="col-md-12 d-grid">
                  <button
                    type="submit"
                    className="btn btn-app py-3 fs-5 fw-bold text-uppercase">
                    <b>Add</b>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAccount;
