import { useState, useEffect } from "react";
import OpenCloseBalance from "components/OpenCloseBalance";
import Txn from "./Txn";
import { transactionAPI, accountAPI } from "services";
import useUniversalStore from "store/universalStore";
import {
  ReminderAlert
} from "components";

const TxnsListView = ({monthlyDate, animation, setAnimation}) => {
    const txns = useUniversalStore((state) => state.transactions);
    const accountId = useUniversalStore((state) => state.accountId);
    const [openBalance, setOpenBalance] = useState(0);
    const [closeBalance, setCloseBalance] = useState(0);
    //const [showReminder, setShowReminder] = useState(false);
  
    //TODO : optimize it and move to relevant component.
    // const overDue =
    //   currentDate >= account?.get("date") && account?.get("enabled");

    useEffect(() => {
      calculateOpeningClosingBalance();
    }, [txns]);

    useEffect(() => {
      calculateOpeningClosingBalance();
    }, [monthlyDate, accountId]);


    const calculateOpeningClosingBalance = async () => {
      //Fetching Opening Balance
      const startDate = new Date(monthlyDate.getFullYear(),monthlyDate.getMonth(),
        1,0,0,0,0);
      setOpenBalance(await transactionAPI.sumTillDateByAccount(startDate, accountId));

      //Fetching Closing Balance
      const endDate = new Date(monthlyDate.getFullYear(),monthlyDate.getMonth() + 1,
        0,23,59,59,999);
      setCloseBalance(await transactionAPI.sumTillDateByAccount(endDate, accountId));
    }
    // useEffect(() => {
    //   if (overDue) {
    //     setShowReminder((prev) => true);
    //   } else {
    //     setShowReminder((prev) => false);
    //   }
    // }, [overDue]);

    // ${showReminder ? "opacity-25" : ""} 
            
    return (
      <div style={{ overflowY: "scroll", zIndex: -100 }}>
        <div
          className={`background h-auto mb-auto pb-5 overflow-hidden 
            scrollbar-design slide ${animation}`}
            onAnimationEnd={() => setAnimation("")}>
          <div className="row">
            <div className="col-md-6 text-center text-success">
                <i className="bi bi-arrow-down-circle-fill fs-3"></i>
                <span className="fs-3 text-uppercase px-3">
                  <b>In</b>
                </span>
              </div>
              <div className="col-md-6 text-center text-danger">
                <i className="bi bi-arrow-up-circle-fill fs-3"></i>
                <span className="fs-3 text-uppercase px-3">
                  <b>Out</b>
                </span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <OpenCloseBalance type="open" sum={openBalance} />
              <div className="list-group list-group-flush">
                {txns?.filter((txn) => txn.get("amount") > 0)
                  ?.filter((txn) => !txn.cancelled)
                  ?.map((txn, i) => <Txn data={txn} key={i} />)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="list-group list-group-flush">
                {txns?.filter((txn) => txn.get("amount") < 0)
                  ?.filter((txn) => !txn.cancelled)
                  ?.map((txn, i) => <Txn data={txn} key={i} />)}
                <OpenCloseBalance type="close" sum={closeBalance} />
              </div>
            </div>
          </div>

          {/* <ReminderAlert
            currentUser={currentUser}
            account={accountId}
            accountId={accountId}
            setShowReminder={setShowReminder}
            showReminder={showReminder}
          /> */}

        </div>
      </div>
    );
  };

export default TxnsListView;