import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "services";

const Menu = () => {
  let navigate = useNavigate();

  const logout = () => {
    auth.logout();
    location.replace("/login");
  };

  const gotopage = (page) => {
    navigate(page);
  };

  return (
    <div className="offcanvas offcanvas-end text-danger"
      tabIndex="-1" id="menuDrawer">
      <div className="offcanvas-header bg-app text-light">
        <h1 className="offcanvas-title">Menu</h1>
        <button
          type="button"
          className="btn-close text-reset bg-light"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <ul className="list-unstyled text-dark">
          <li className="my-3">
            <button
              className="fs-4 btn btn-outline-app w-100 text-start mb-1 py-3"
              onClick={() => gotopage("/profile")}
              data-bs-dismiss="offcanvas"
            >
              <i className="bi bi-person-circle pe-1"></i> Profile
            </button>
          </li>
          
          <li className="my-3">
            <button
              className="fs-4 btn btn-outline-app w-100 text-start mb-1 py-3"
              onClick={() => gotopage("/settings")}
              data-bs-dismiss="offcanvas"
            >
              <i className="bi bi-gear pe-1"></i> Settings
            </button>
          </li>
          <li className="my-3">
            <button
              className="fs-4 btn btn-outline-app w-100 text-start mb-1 py-3"
              onClick={() => gotopage("/help")}
              data-bs-dismiss="offcanvas"
            >
              <i className="bi bi-info-circle-fill pe-1"></i> Help & Support
            </button>
          </li>

          <li>
            <div className="row my-3 sticky-bottom-drawer">
              <div className="col-md-12 d-grid">
                <a className="fs-4 btn btn-app text-start py-3"
                  role="button" onClick={logout}>
                  <i className="bi bi-arrow-left pe-1"></i>
                  <span className="fs-3 ms-2">Logout</span>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;