import React, { useState, useEffect, useCallback } from "react";
import { 
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { accountAPI } from "services";
import Account from "./Account";

import ExpandableButton from "components/ExpandableButton";
//import { useOnboardingStore } from "store/onboarding-store";
import useUniversalStore from "store/universalStore";
import Filters from "./Filters";
import { 
  ConfirmationModal, Loader
} from "components";

const KhataList = () => {
  // BUG : Selecting an account down at the account list ... scrolls the list upwards.. 
  // Known Optimizations : ConfirmationModal can be moved to Account/SubMenu.js for lite UI instead of showing every time.

  //const goToNextStep = useOnboardingStore((store) => store.goToNextStep);
  const accounts = useUniversalStore((state) => state.accounts);
  const write = useUniversalStore((state) => state.write);
  const [selectedId, setSelectedId] = useState(null);
  const [filteredAccounts, setFilteredAccounts] = useState([]);

  const goToKhata = (_id) => {
    setSelectedId(_id);

    // Update the accountId in the global state
    write({ accountId: _id });
  };

  // Handle account closure
  const closeAccount = async (e) => {
    e.preventDefault();
    const account = accounts.find(account => account.id === selectedId);
    if (account.get("balance") == 0) {
      const result = await accountAPI.closeAccount(account.id);
      if(result){
      
      //Fetch updated accounts list
      const accounts = await accountAPI.getAccounts();

      //Set the updated accounts list in global state & reset the selected account
      write({
          accounts: accounts,
          accountId: null
      });
      setSelectedId(null);
      }
    } else {
      alert("Can't delete active account ");
    }
  }

  if(!filteredAccounts) return <Loader />;

  return (
    <>
      <div className="d-flex flex-column vh-100">
        <Filters 
          setFilteredAccounts={setFilteredAccounts} />

        <ul className="list-group list-group-flush scrollbar-design">
            {filteredAccounts.map((account, rowId) => (
              <Account 
                key={rowId}
                account={account}
                isSelected={account.id === selectedId}
                onSelect={() => {
                  if(!account.get("cancelled")) {
                    goToKhata(account.id);
                  }
                }}/>
            ))}
        </ul>
      </div>

      <div className="fixed-bottom" style={{ width: 300, marginLeft: 75, 
          paddingBottom: 50, paddingRight: 0, opacity: 0.85}}>
        <ExpandableButton
          title="Add Account"
          clickForm="addAccountDrawer"
          // onClick={(e) => goToNextStep()}
          icon={faUserPlus} />
      </div>
      <ConfirmationModal id="closeAccountModal" onClick={closeAccount} />
    </>
  );
}

export default KhataList;