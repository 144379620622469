import React from "react";
import { auth } from "services";
import useUniversalStore from "store/universalStore";

const EmailVerify = () => {
    const user = useUniversalStore((state) => state.user);

    const verifyEmailHandler = async (e) => {
        e.preventDefault();
        const result = await auth.sendVerificationEmail(user.get("email"));
        console.log(result);
        // success(result);
        // failure(cause);
        // }).catch((cause) => {
        //   failure(cause);
        // })
      }

    return (
        <>
        {user.get('emailVerified') ? (
            <span className="position-absolute start-0 mt-2 text-success">
                <i className="bi bi-patch-check-fill"></i>{' '}
                Email Verified!
            </span>
        ) : (
            <span className="mt-2 text-app" style={{float: 'right'}}>
                <span className="">
                    <i className="bi bi-shield-fill-exclamation"></i>{' '}
                    Email not verified!
                </span>
                <a onClick={verifyEmailHandler}
                    className="btn btn-sm btn-outline-app ms-5">
                    Send verification Email
                </a>
            </span>
         )}
         </>
    )
}

export default EmailVerify;