import React from 'react';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';

const Test = () => {
    const {
        control,
        register, 
        handleSubmit, 
        formState: { errors } 
    } = useForm({
        defaultValues: {
            firstName: "",
            lastName: "",
            age: 0
        }
    });

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ];

    return (
        <div className="container">
            <h1 className="text-center">A Sample Form</h1>
            <form onSubmit={handleSubmit((data) => console.log(data))}
                className="mt-5">
                <div className="mb-3">
                    <input type="text"
                        placeholder="First Name"
                        {...register("firstName", { required: true }) } />
                    {errors.firstName && <span>{errors.firstName.message}</span>}
                </div>
                <div className="mb-3">
                    <input type="text" 
                        placeholder="Last Name"
                        {...register("lastName", { required: true }) } />
                    {errors.lastName && <span>This field is required</span>}
                </div>
                <div className="mb-3">
                    <input 
                        type="number"
                        placeholder="Age"
                        {...register("age", { required: true, min: 18, max: 99 }) } />
                    {errors.age && <span>{errors.age.message}</span>}
                </div>
                <div className="mb-3">
                    {/* <Controller
                        control={control}
                        name="selectedOption"
                        render={({ field }) => <Select
                            options={options}
                            placeholder="Select an option"
                            {...field} />
                        }
                    /> */}
                    <Select
                        options={options}
                        placeholder="Select an option"
                        onChange={(selectedOption) => setValue("selectedOption", selectedOption)}
                    />
                </div>
                <input type="submit" className="btn btn-primary btn-block" />
            </form>

        </div>
    )
}

export default Test;