import React from 'react';
import { withTranslation } from 'react-i18next';

function Footer(){
  return (
    <footer className="py-2 bg-app text-center fixed-bottom">
      <div className="row">
        <span className="text-white">Copyright - 2024-25 @ Bahikhata Inc.</span>
      </div>
    </footer>
  )
}

export default Footer;
