import React, { useEffect, useState } from "react";
import RecursiveTxns from "./Recursive/RecursiveTxns";
import OneTimeTxn from "./OneTimeTxn";
import { useLocation } from "react-router-dom";
import { hideOffCanvas } from "utils";
import { Loading, Status } from "components";
import useUniversalStore from "store/universalStore";

function NewTxn() {
  const [txnType, setTxnType] = useState("onetime");
  

  useEffect(() => {
    // document.getElementById("addTxnDrawer")
    //   ?.addEventListener("hide.bs.offcanvas", (e) => {
    //     setTxnType(() => "onetime");
    //   });
  }, []);
  
  /*
  <div className="offcanvas offcanvas-start text-danger"
      tabIndex="-1"
      id="addAccountDrawer">
      <div className="offcanvas-header bg-app text-light">
        <h3 className="offcanvas-title">Add Account</h3>
        <button
          type="button"
          className="btn-close text-reset bg-light"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body container-fluid">
        <div className="row">
          <div className="col-12">
          */
  return (
    <div className="offcanvas offcanvas-end text-danger"
      tabIndex="-1"
      id="addTxnDrawer">
      <div className="offcanvas-header bg-app text-light">
        <h3 className="offcanvas-title">Add Transaction</h3>
        <button
          type="button"
          className="btn-close text-reset bg-light"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body container-fluid ">
        <div className="row bg-white mb-5">
          <div className="col-12"></div>
            <div className="btn-group w-100 py-3" role="group">
              <button type="button" className="btn btn-app btn-block" 
                  onClick={(e) => setTxnType("onetime")}>
                    One Time
              </button>
              <button type="button" className="btn btn-outline-app btn-block" 
                  onClick={(e) => setTxnType("recurring")}>
                    Recurring
              </button>
            </div>
            <OneTimeTxn />
            {/* {txnType === "onetime" ? <OneTimeTxn /> : <RecursiveTxns /> } */}
          </div>
        </div>
      </div>
  );
}
export default NewTxn;
